import React from "react";
import {useState} from "react";
import { Link } from "react-router-dom";
import axios from 'axios';

//* fontawesome
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope
} from "react-icons/fa";

const Footer = () => {

  const [email, setEmail] = useState("");
  const [sent, setSent] = useState("");


    async function onSubmitHandler(e) {
    e.preventDefault();
    let data = {
      email:email

    };

    const form = await axios
      .post("/api/form", {type:"newsLetter",data:data})
      .then(res => {
        setSent(true);
        resetForm();
      })
      .catch(() => {
        console.log("message not sent");
      });
  }

   // for reset inital data
  function resetForm() {
    setEmail("");
    setTimeout(() => {
      setSent(false);
    }, 3000);
  }

  return (
    <div>
      <footer>
        <div className="container">
          <div className="row-1">
            {/* <div className="footer-item footer-about">
              <h2>about us</h2>
              <p>
                Anjum Art Press is printing press and packages company. We are
                the pioneers in Offset printing and packaging in Pakistan. At
                Anjum Art Press (Pvt.) Ltd., our efforts are always focused on
                maintaining the highest level of quality for our customers . Our
                every member is committed to putting their best efforts in
                making the company successful in the Printing & Packaging
                Industry.
              </p>
              <div className="social-links">
                <Link className="link" to="#">
                  <FaFacebookF />
                </Link>
                <Link className="link" to="#">
                  <FaTwitter />
                </Link>
                <Link className="link" to="#">
                  <FaInstagram />
                </Link>
                <Link className="link" to="#">
                  <FaLinkedinIn />
                </Link>
              </div>
            </div> */}
            <div className="footer-item footer-quick-link">
              <h2>quick links</h2>
              <ul>
                <li>
                  <Link className="link" to="/">
                    Home
                  </Link>
                </li>

                <li>
                  <Link className="link" to="/about">
                    About
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/services">
                    Service
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/careers">
                    Careers
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/contacts">
                    Contacts
                  </Link>
                </li>
              </ul>
            </div>

            <div className="footer-item footer-contact">
              <h2>contact us</h2>
              <p>
                <FaMapMarkerAlt className="i" /> Plot No. A/76, S.I.T.E., GPO
                Box No. 400, Hyderabad-71900, Sindh, Pakistan.
              </p>

              <p>
                <FaPhoneAlt className="i" /> +92-307-2111333
                <ul>
                  <li>+92-333-2111333</li>
                  <li>+92-22-3413551-5</li>
                </ul>
              </p>

              <p>
                <FaEnvelope className="i" />
                info@anjumpress.com
              </p>
            </div>

            <div className="footer-item footer-newsletter">
              <h2>newsletter</h2>
              <form onSubmit={onSubmitHandler}>
                <div className="input-group">
                  <input
                    type="email"
                    placeholder="Your Email"
                    className="form-control"
                    value={email}
                    onChange={(e)=> setEmail(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="btn btn-1">
                  subscribe
                </button>
               {sent && <p className="message">Message sent</p>} 

              </form>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
