import React from "react";
import comapnyImage from '../../images/about/Factor.png';
const CompanyProfile = () => {
  return (
    <div className="intro-letter">
      <div className="container">
        <div className="row-1">
          <div className="intro-letter-text">
            <div className="inner-text">
              <h1>COMPANY PROFILE</h1>
              <img src={comapnyImage} alt="" />
              <p>
                <p className="para-1">
                  Anjum Art Press (Pvt.) Ltd. was founded in 1964 at the area of
                  1 acre (43,000 Sq. Ft.) The director acquired wide knowledge
                  and experience within long years of working for the printing
                  and press businesses; having thereby occupied leading
                  positions.
                </p>
                <p className="para-2">
                  Over the years, the company successfully acquired the leading
                  position as the largest Press in the town and a highly
                  satisfied number of customers. To cope with the increasing
                  demand from our valued customers , we have taken another step
                  towards growth in the expansion of our product line the
                  company is registered with the Securities and Exchange
                  Commission of Pakistan as a private limited firm.
                </p>
                <p className="para-3">
                  We are the pioneers in Offset printing and packaging in
                  Pakistan. At Anjum Art Press (Pvt.) Ltd., our efforts are
                  always focused on maintaining the highest level of quality for
                  our customers . Our every member is committed to putting their
                  best efforts in making the company successful in the Printing
                  & Packaging Industry
                </p>
                <p className="para-4">
                  As a leader in the industry since 1964, Anjum Art Press (Pvt.)
                  Ltd. has a wealth of knowledge , skills , and experience in
                  everything related to the manufacturing of boxes. We’re
                  committed to providing a variety of companies and individuals
                  with quality products and innovations.
                </p>

                <p className="para-5">
                  To consolidate and cultivate confidence in Anjum Art Press
                  (Pvt.) Ltd. , competence is therefore the primary ambition of
                  the company.
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfile;
