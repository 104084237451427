import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./css/app.css";
import "./css/contants.css";
import './css/about.css';
import './css/service.css';
import './css/careers.css';
// import App from "./components/slider.jsx"
ReactDOM.render(
  (<React.StrictMode>
    <App />
  </React.StrictMode>),
  document.getElementById("root")
);
