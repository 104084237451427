/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from '../images/logo.png'
import { Link } from "react-router-dom";
export default function Navbar() {
  return (
    <div>
      <header className="header">
        <div className="container">
          <div className="row-1 justify-content-between align-items-center">
            <div className="logo">
              <Link to="/">
              <img src={logo} alt="logo" width={80} height={40} />
              </Link>
            </div>
            <input type="checkbox" id="nav-check" />
            <label htmlFor="nav-check" className="nav-toggler">
              <span />
            </label>
            <nav className="nav">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/services">Service</Link>
                </li>
                <li>
                  <Link to="/careers">Careers</Link>
                </li>
                <li>
                  <Link to="/contacts">Contacts</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </div>
  );
}
