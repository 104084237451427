import React from "react";

import cakeBox from "../../images/service/sweetsAndBakers/cake-box.jpeg";
import pastry from "../../images/service/sweetsAndBakers/cake-box-2.jpeg";
import customPrintedCakeBox from "../../images/service/sweetsAndBakers/custom-printed-cake-boxes.jpg";
import donutBox from "../../images/service/sweetsAndBakers/donut-box.jpg";
import donutBox2 from "../../images/service/sweetsAndBakers/donut-box-2.jpg";
// import giftBoxDesign from "../../images/service/sweetsAndBakers/gift-box-design.jpg";
// import khariPackingBox from "../../images/service/sweetsAndBakers/khari-packaging-box-500x500.jpg";
import sweetBox from "../../images/service/sweetsAndBakers/sweet-box.jpg";
import bakers from "../../images/service/sweetsAndBakers/bakers.jpeg";
import biscuit from "../../images/service/sweetsAndBakers/biscuitBox.jpeg";

//* Image Loader
import { LazyLoadImage } from "react-lazy-load-image-component";

const SweetsAndBakers = () => {
  return (
    <div className="row-1">
      <div className="service-cards align-items-center justify-content-center">
        <div className="row-1">
          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"cake box"} src={cakeBox} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Cake Boxes</h3>
            </div>
          </div>

            <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"cake box"} src={customPrintedCakeBox} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Cake Boxes</h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"sweet Box"} src={sweetBox} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Sweet Boxes</h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"donut box"} src={donutBox} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>donut boxes</h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"donut box"} src={donutBox2} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>donut boxes</h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"pastry"} src={pastry} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Pastry Boxes</h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"bakers"} src={bakers} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Cookies</h3>
            </div>
          </div>

             <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"biscuit"} src={biscuit} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Biscuit</h3>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default SweetsAndBakers;
