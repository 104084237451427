import React from "react";
import Slider from "./slider";
import heildbergImg from "../images/heildberg.jpg";
// import { Link } from "react-router-dom";
import Carasual from "./carasual";
import { useState, useEffect } from "react";
import pharmacyICon from "../images/ourProducts/pharmacy-new-icon.png";
import educationIcon from "../images/ourProducts/education-new-icon.png";
import fmcgIcon from "../images/ourProducts/fmcg-icon.png";
import miscIcon from "../images/ourProducts/mics-icon.png";
import tobaccoIcon from "../images/ourProducts/sweetAndBakers.png";
import toffeIcon from "../images/ourProducts/toffee-confectionery-icon.png";
import axios from "axios";
import "animate.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Title from "./title";

const Header = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [sent, setSent] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 2000,
      disable: function() {
        var maxWidth = 800;
        return window.innerWidth < maxWidth;
      }
    });
    AOS.refresh();
  }, []);

  async function onSubmitHandler(e) {
    e.preventDefault();
    let data = {
      name: name,
      phone: phone,
      message: message
    };

    const form = await axios
      .post("/api/form", { type: "query", data: data })
      .then(res => {
        setSent(true);
        resetForm();
      })
      .catch(() => {
        console.log("message not sent");
      });
  }

  // for reset inital data
  function resetForm() {
    setName("");
    setPhone("");
    setMessage("");

    setTimeout(() => {
      setSent(false);
    }, 3000);
  }

  return (
    <div>
      <Title title="Anjum Art Press" />

      <section class="home-section section">
        {/* <!-- effect wrap start --> */}
        <div class="effect-wrap">
          <div class="effect effect-1" />
          <div class="effect effect-2">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
          <div class="effect effect-3" />
          <div class="effect effect-4" />
          <div class="effect effect-5">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>

          {/* <div class="ocean">
            <div class="wave" />
            <div class="wave" />
            <div class="wave" />
          </div> */}
        </div>
        {/* <!-- effect wrap end --> */}

        <div className="container h-100">
          <div className="row-1">
            <div className="home-text">
              <div className="home-text-inner">
              <h3>since  1964</h3>
                <h1
                  className="tracking-in-expand"
                  data-heading="Anjum Art Press"
                >
                  <span>(PVT) LTD.</span>
                </h1>
              </div>
            </div>
          </div>

          <div class="row-1 home-about-content justify-content-center">
            <div class="about-info">
              <div className="card-info">
                <div className="card-2">
                  <p>
                    {/* <span>Packing and printing solution</span> */}
                    <span>At anjum art (Pvt) Ltd,</span>
                    <p>
                      {" "}our efforts are to seek customer satisfaction by
                      providing quality service to our valued clients, we rank
                      second to none in the town in the field of Printing Press
                      Industry and Anjum Art Press (Pvt.) Ltd. has earned the
                      title of “The Largest Press of Hyderabad”.
                    </p>
                  </p>
                </div>
              </div>
            </div>

            <div className="home-img">
              <div className="slider">
                <Slider />
              </div>

              {/* <img src={heildbergImg} className="" alt="about image" /> */}
            </div>
          </div>
        </div>
      </section>

      {/* home-section end -> */}

      {/* Welcome Section start-> */}
      <section className="welcome-section section" id="about-us">
        {/* <!-- effect wrap start --> */}
        <div class="effect-wrap">
          {/* <div className="effect effect-6" /> */}
        </div>

        <div className="container">
          <div class="row-1">
            {/* <div className="welcome-space" /> */}
            <div class="welcome-img">
              <div class="img-box">
                <img src={heildbergImg} className="" alt="about image" />
              </div>
            </div>

            <div data-aos="fade-left" class="welcome-info">
              <h2 className="tracking-in-expand" data-heading="">
                <span>About Us</span>
              </h2>
              <p>
                <p>
                  Anjum Art Press (Pvt.) Ltd. was founded in 1964 at the area of
                  1 acre (43,000 Sq. Ft.) The director acquired wide knowledge
                  and experience within long years of working for the printing
                  and press businesses; having thereby occupied leading
                  positions.{" "}
                </p>

                <p>
                  Over the years, the company successfully acquired the leading
                  position as the largest Press in the town and a highly
                  satisfied number of customers. To cope with the increasing
                  demand from our valued customers , we have taken another step
                  towards growth in the expansion of our product line the
                  company is registered with the Securities and Exchange
                  Commission of Pakistan as a private limited firm.
                </p>
              </p>
              {/* 
              <a href="#" class="btn-1 outer-shadow hover-in-shadow">
                Read More
              </a> */}
            </div>
          </div>
        </div>
      </section>

      {/* Welcome Section end -> */}

      {/* Service Section start -> */}
      <section
        data-aos="fade-up"
        data-aos-anchor-placement="bottom-bottom"
        className="service-section section"
      >
        <div className="container">
          <div className="row-1">
            <div className="service-text align-items-center justify-content-center">
              <h1 className="tracking-in-expand" data-heading="">
                Our Services
              </h1>
            </div>

            <div className="service-slider">
              <Carasual />
            </div>
          </div>
        </div>
      </section>

      {/* Service Section end -> */}

      {/* expertise Section start -> */}
      <section className="expertise-section section">
        <div className="container">
          <div className="expertise-intro">
            <div className="expertise-inner-text">
              <h1>Our Expertise</h1>
              <p>Our company's core products</p>
            </div>
          </div>

          <div className="row-1">
            <div data-aos="zoom-in" className="expertise-item">
              <div className="row-1">
                <div className="expertise-item-img">
                  <img src={pharmacyICon} alt="" />
                </div>
                <div className="expertise-item-text">
                  <h3>PHARMACEUTICAL</h3>
                  <p>
                    Designing and printing of pharmaceutical boxes of all sizes
                  </p>
                </div>
              </div>
            </div>

            <div data-aos="zoom-in" className="expertise-item">
              <div className="row-1">
                <div className="expertise-item-img">
                  <img src={toffeIcon} alt="" />
                </div>
                <div className="expertise-item-text">
                  <h3>Food</h3>
                  <p>
                    Designing and printing of confectionary boxes of all sizes
                    and boards
                  </p>
                </div>
              </div>
            </div>

            <div data-aos="zoom-in" className="expertise-item">
              <div className="row-1">
                <div className="expertise-item-img">
                  <img src={educationIcon} alt="" />
                </div>
                <div className="expertise-item-text">
                  <h3>Education</h3>
                  <p>Printing and binding of All kind noted books and copies</p>
                </div>
              </div>
            </div>

            <div data-aos="zoom-in" className="expertise-item">
              <div className="row-1">
                <div className="expertise-item-img">
                  <img src={fmcgIcon} alt="" />
                </div>
                <div className="expertise-item-text">
                  <h3>FMCG</h3>
                  <p>
                    Manufacture offset printed boxes of all consumer
                    commodities
                  </p>
                </div>
              </div>
            </div>

            <div data-aos="zoom-in" className="expertise-item">
              <div className="row-1">
                <div className="expertise-item-img">
                  <img src={tobaccoIcon} alt="" />
                </div>
                <div className="expertise-item-text">
                  <h3>Sweets & Bakers</h3>
                  <p>
                    Designing and printing of all sizes of bakers and sweets
                    boxes
                  </p>
                </div>
              </div>
            </div>

            <div data-aos="zoom-in" className="expertise-item">
              <div className="row-1">
                <div className="expertise-item-img">
                  <img src={miscIcon} alt="" />
                </div>
                <div className="expertise-item-text">
                  <h3>MISCELLANEOUS</h3>
                  <p>
                    Manufacture of all kinds of consumer goods and other boxes
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* expertise Section end -> */}

      {/* Query Section start -> */}
      <section className="service-section section">
        <div className="container">
          <div className="row-1">
            <div
              data-aos="zoom-out-left"
              className="service-text align-items-center justify-content-center"
            >
              <h1 className="tracking-in-expand" data-heading="Submit">
                Your Query
              </h1>
            </div>

            <div data-aos="zoom-out-right" className="query-form">
              <form onSubmit={onSubmitHandler}>
                <div className="input-group">
                  <input
                    type="text"
                    value={name}
                    placeholder="Name"
                    className="form-control"
                    onChange={e => setName(e.target.value)}
                    required
                  />
                </div>

                <div className="input-group">
                  <input
                    type="tel"
                    placeholder="number"
                    // pattern="[0-9]{11}"
                    className="form-control"
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    maxLength={12}
                    required
                  />
                </div>

                <div className="input-group">
                  <textarea
                    placeholder="Message"
                    value={message}
                    className="form-control"
                    onChange={e => setMessage(e.target.value)}
                    required
                  />
                </div>

                <button type="submit" className="btn-1 btn">
                  send
                </button>

                {sent && <p className="message">Message sent</p>}
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* Quey Section end -> */}
    </div>
  );
};

export default Header;
