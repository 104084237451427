/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { useState } from "react";
import img from "../../images/avatar/profileImage.png";
import { FaFileUpload } from "react-icons/fa";
import Title from '../title';
import axios from "axios";
import userImg from '../../images/user.png'


const Careers = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [position, setPosition] = useState();
  const [message, setMessage] = useState();
  const [image, setImage] = useState(userImg);
  const [file, setFile] = useState();
  const [sent, setSent] = useState(false);
  const [disable, setDisable] = useState(false);
  const [localMessage, setLocalMessage] = useState();
  const [fileUploadMessage,setFileUploadMessage] = useState("File Upload")

  const hangeProfileImage = e => {
    let imageFile = e.target.files[0];
    let reader = new FileReader();

    reader.onload = e => {
      if (reader.readyState === 2) {
        setImage(reader.result);
      }
    };

    reader.readAsDataURL(imageFile);
  };

  const handleFileChange = e => {
    let pdfFile = e.target.files[0];
    console.log(pdfFile.name);
    let reader = new FileReader();

    reader.onload = e => {
      if (reader.readyState === 2) {
        setFile(reader.result);
        setFileUploadMessage("File Uploaded")
      }
    };

    reader.readAsDataURL(pdfFile);
  };

  async function onSubmitHandler(e) {
    e.preventDefault();
    if (image === undefined || image === "") {
      setDisable(true);
      setLocalMessage("please upload profile image");
      setSent(true);

      setTimeout(() => {
        setDisable(false);
        setLocalMessage("");
        setSent(false);
      }, 1000);
    } else {
      setDisable(true);
      let data = {
        name: name,
        email: email,
        phone: phone,
        file: file,
        image: image,
        message: message
      };

      let formData = new FormData();
      formData.append("name", name);
      formData.append("email", name);
      formData.append("phone", phone);
      formData.append("message", message);
      formData.append("file", file);
      formData.append("image", image);

      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };

      const form = await axios
        .post("/api/form", { type: "career", data })
        .then(res => {
          setSent(true);
          resetForm();
          setLocalMessage("Message sent");
        })
        .catch(() => {
          console.log("message not sent");
          setDisable(false);
        });
    }
  }

  // for reset inital data
  function resetForm() {
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
    setFile("");
    setImage(userImg);
    setFileUploadMessage("File Upload")
    setTimeout(() => {
      setSent(false);
      setDisable(false);
      setLocalMessage("");
    }, 3000);
  }

  return (
    <div className="careers">
    <Title title="Careers"/>
      <div className="container">
        <div className="careers-card">
          <h1>Apply For Job</h1>

          <div class="avatar-upload">
            <div class="avatar-edit">
              <form action="" method="post" id="form-image">
                
                <input
                  type="file"
                  id="imageUpload"
                  accept=".png, .jpg, .jpeg"
                  onChange={hangeProfileImage}
                  required
                />

                <label for="imageUpload" />
              </form>
            </div>

            <div class="avatar-preview">
              <img
                class="profile-user-img img-responsive img-circle"
                id="imagePreview"
                src={
                  image
                    ? image
                    : "https://adminlte.io/themes/AdminLTE/dist/img/user3-128x128.jpg"
                }
                alt={"User profile picture"}
              />
            </div>
          </div>

       

          <div className="careers-form">
            {/* <div className="query-form"> */}
            <form onSubmit={onSubmitHandler}>
              <div className="row-1">
                <div className="input-group careers-input-odd ">
                  <input
                    type="text"
                    placeholder="Name"
                    class="form-control"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    required
                  />
                </div>

                <div className="input-group careers-input-even">
                  <input
                    type="email"
                    placeholder="Email"
                    class="form-control"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="row-1">
                <div className="input-group careers-input careers-input-odd">
                  <input
                    type="number"
                    placeholder="Phone"
                    class="form-control"
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    required
                  />
                </div>
                

                <div className="input-group careers-input careers-input-even">
                  <div className="button-wrapper">
                    <span className="label">
                      <FaFileUpload />{" "+fileUploadMessage}
                    </span>

                    <input
                      type="file"
                      name="upload"
                      id="upload"
                      className="upload-box"
                      placeholder="Upload File"
                      onChange={handleFileChange}
                      accept=".pdf"
                    />
                  </div>
                </div>

                 

              </div>

          

              <div className="input-group careers-input position-input">
                  <input
                    type="text"
                    placeholder="Position"
                    class="form-control"
                    value={position}
                    onChange={e => setPosition(e.target.value)}
                    required
                  />
                </div>

              <div className="input-group">
                <textarea
                  placeholder="Message"
                  className="form-control"
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                  required
                />
              </div>

              <div className="message_btn">
                <button
                  type="submit"
                  className={`btn-1 btn ${disable ? "btn-disable" : ""}`}
                >
                  send
                </button>
                <p>
                  {sent ? localMessage : ""}
                </p>
              </div>
            </form>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Careers;
