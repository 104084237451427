/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Carousel } from "react-carousel-minimal";
import box1 from "../images/productImages/box-1.jpg";
import box2 from "../images/productImages/box-2.png";
import box3 from "../images/productImages/box-3.jpg";
import customPacking from "../images/productImages/customPacking.jpeg";
import fastFood from "../images/productImages/fastFood.jpeg";
import candyBoxs from "../images/productImages/candy-boxes.jpg";
import confectionery from "../images/productImages/confectionery.jpeg";
import cosmeticBox1 from "../images/productImages/cosmetic-boxes-1.jpg";
import cosmeticBox2 from "../images/productImages/cosmetic-box-2.png";
import cosmeticBox3 from "../images/productImages/cosmetic-box-3.png";
import cosmeticBox4 from "../images/productImages/cosmetic-box-4.jpg";
import foldinCartoon from "../images/productImages/folding-cartons.jpg";
import foodPacking from "../images/productImages/food-packaging.jpeg";
 

function App() {
  const data = [
    {
      image: box1,
      caption: ""
    },
    {
      image: box2,
      caption: ""
    },
    {
      image: box3,
      caption: ""
    },
    {
      image: customPacking,
      caption: ""
    },
    {
      image: fastFood,
      caption: ""
    },
    {
      image: candyBoxs,
      caption: ""
    },
    {
      image: confectionery,
      caption: ""
    },
    {
      image: cosmeticBox1,
      caption: ""
    },
    {
      image: cosmeticBox2,
      caption: ""
    },
    {
      image: cosmeticBox3,
      caption: ""
    },
    {
      image: cosmeticBox4,
      caption: ""
    },
    {
      image: foldinCartoon,
      caption: ""
    },
    {
      image: foodPacking,
      caption: ""
    }
  ];

  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold"
  };
  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold"
  };
  return (
    <div>
      <Carousel
        data={data}
        time={2000}
        captionStyle={captionStyle}
        radius="10px"
        slideNumber={true}
        slideNumberStyle={slideNumberStyle}
        captionPosition="bottom"
        automatic={true}
        dots={true}
        pauseIconColor="white"
        pauseIconSize="40px"
        slideBackgroundColor="white"
        slideImageFit="cover"
        thumbnails={false}
        thumbnailWidth="100px"
        style={{
          //   textAlign: "center",
          maxWidth: "850px",
          maxHeight: "500px",
          margin: "40px auto"
        }}
      />
    </div>
  );
}

export default App;
