import React from "react";
import hangTang from "../../images/service/more/hangTang.jpeg";
import hangTang2 from "../../images/service/more/hangTang2.jpeg";
import label1 from "../../images/service/more/label.jpg";
import label2 from "../../images/service/more/label-2.jpeg";
import label3 from "../../images/service/more/label-3.jpg";
import sticker1 from "../../images/service/more/stickers.jpg";
import sticker2 from "../../images/service/more/stcikers-2.png";
import hanger from "../../images/service/more/hanger.jpeg";

import { LazyLoadImage } from "react-lazy-load-image-component";

const LabelAnsStickers = () => {
  return (
    <div className="row-1">
      <div className="service-cards align-items-center justify-content-center">
        <div className="row-1">
          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"hangTang"} src={hangTang} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Tag cards</h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"hangTang2"} src={hangTang2} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Tag Cards</h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"hanger"} src={hanger} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Hanger</h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"razerHanger"} src={label1} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Label</h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"razerHanger"} src={label2} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Label</h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"razerHanger"} src={label3} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Label</h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"sticker"} src={sticker1} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Stickers</h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"sticker"} src={sticker2} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Stickers</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LabelAnsStickers;
