import React from "react";

import drops from "../../images/service/pharmacy/drops.jpeg";
import eyeDrops from "../../images/service/pharmacy/eye-drops.jpeg";
import syrup from "../../images/service/pharmacy/syrup.jpeg";
import syrub2 from "../../images/service/pharmacy/syrup-2.jpeg";
import tube1 from "../../images/service/pharmacy/tube.jpeg";
import tube2 from "../../images/service/pharmacy/tube-2.jpeg";
import tube3 from "../../images/service/pharmacy/tube-3.jpeg";
import tablets from "../../images/service/pharmacy/tablets.jpeg";
import faceCream from "../../images/service/pharmacy/faceCream.png";
import creamBox from "../../images/service/pharmacy/creamBox.jpeg";

import { LazyLoadImage } from "react-lazy-load-image-component";

const Pharmaseutical = () => {
  return (
    <div className="row-1">
      <div className="service-cards align-items-center justify-content-center">
        <div className="row-1">
          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"syrup"} src={syrup} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>syrup </h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"tablet"} src={tablets} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Tablet </h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"drops"} src={drops} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Drops</h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"drops"} src={eyeDrops} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Drops</h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"tube"} src={tube1} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Tube</h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"tube"} src={tube2} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Tube</h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"tube"} src={tube3} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Tube</h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"faceream"} src={faceCream} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Cream</h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"creamBox"} src={creamBox} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Cream</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pharmaseutical;
