/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from "react";
//* Components
import Certification from "./certification";
import QualityPolicy from "./qualityPolicy";
// import IntroductionLetter from "./introductionLetter";
import CompanyProfile from "./companyProfile";
//* Inline SVG
import SVG from "react-inlinesvg";
//*Hooks
import { useState } from "react";
import Title from '../title';
import { FaCertificate } from "react-icons/fa";
import { ImProfile } from "react-icons/im";
import { MdHighQuality } from "react-icons/md";
import { RiBookletFill } from "react-icons/ri";

const About = () => {
  const [currentComponent, setCurrentComponent] = useState(<Certification />);
  const [drawerWidth, setDrawerWidth] = useState("0px");
  const [activeLink, setActiveLink] = useState({
    certificate: "active-link",
    quality: "",
    introLetter: "",
    companyProfile: ""
  });

  const onClickHandler = e => {
    let data = {
      certificate: "",
      quality: "",
      introLetter: "",
      companyProfile: ""
    };

    if (e === "certificate") {
      setCurrentComponent(<Certification />);
    } else if (e === "quality") {
      setCurrentComponent(<QualityPolicy />);
    }else if (e === "companyProfile") {
      setCurrentComponent(<CompanyProfile />);
    }

    setActiveLink({ ...data, [e]: "active-link" });
  };

  function openNav() {
    setDrawerWidth("250px");
    console.log("hi");
    // document.getElementById("mySidenav").style.width = "250px";
  }

  function closeNav() {
    setDrawerWidth("0");
    // document.getElementById("mySidenav").style.width = "0";
  }
  return (
    <Fragment>
      <Title title="About"/>
      <div className="about-page">
        <div className="side-menu-drawer">
          <div id="mySidenav" class="sidenav" style={{width:drawerWidth}}>
            <a href="javascript:void(0)" class="closebtn" 
            onClick={closeNav}
            >
              &times;
            </a>
           <div className="more-in-about">
              <span>More In About</span>
            </div>

            <ul>
              <li>
                <div
                  href="#"
                  className={activeLink.certificate}
                  onClick={() => onClickHandler("certificate")}
                >
                  <span>Certification</span>{" "}
                  <FaCertificate className="react-icons" />
                </div>
              </li>

              <li>
                <div
                  href=""
                  className={activeLink.quality}
                  onClick={() => onClickHandler("quality")}
                >
                  <span>Quality Policy </span>
                  <MdHighQuality className="react-icons" />{" "}
                </div>
              </li>
{/* 
              <li>
                <div
                  href=""
                  className={activeLink.introLetter}
                  onClick={() => onClickHandler("introLetter")}
                >
                  <span>Introduction Letter</span>
                  <RiBookletFill className="react-icons" />
                </div>
              </li> */}

              <li>
                <div
                  href=""
                  className={activeLink.companyProfile}
                  onClick={() => onClickHandler("companyProfile")}
                >
                  <span>Company Profile</span>
                  <ImProfile className="react-icons" />
                </div>
              </li>
            </ul>
          </div>
          <span
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={openNav}
          >
            &#9776; 
          </span>
        </div>

        <div className="effect-wrap">
          <div className="effect effect-1" />
          <div className="effect effect-7" />
        </div>

        <div className="row-1">
          {currentComponent}

          {/* Side Menu */}
          <div className="about-side-menu">
            <div className="more-in-about">
              <span>More In About</span>
            </div>

            <ul>
              <li>
                <div
                  href="#"
                  className={activeLink.certificate}
                  onClick={() => onClickHandler("certificate")}
                >
                  <span>Certification</span>{" "}
                  <FaCertificate className="react-icons" />
                </div>
              </li>

              <li>
                <div
                  href=""
                  className={activeLink.quality}
                  onClick={() => onClickHandler("quality")}
                >
                  <span>Quality Policy </span>
                  <MdHighQuality className="react-icons" />{" "}
                </div>
              </li>

              {/* <li>
                <div
                  href=""
                  className={activeLink.introLetter}
                  onClick={() => onClickHandler("introLetter")}
                >
                  <span>Introduction Letter</span>
                  <RiBookletFill className="react-icons" />
                </div>
              </li> */}

              <li>
                <div
                  href=""
                  className={activeLink.companyProfile}
                  onClick={() => onClickHandler("companyProfile")}
                >
                  <span>Company Profile</span>
                  <ImProfile className="react-icons" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default About;
