import React from "react";
import { useState, useEffect, useRef } from "react";
// import { Carousel } from "react-bootstrap";

import fmcg from "../images/service/FMCG.png";
import lebelPrinting from "../images/service/label-printing-icon.png";
import commercialPrinting from "../images/service/commercial-printing-icon.png";
import education from "../images/service/education.png";
import broucher from "../images/service/broucher.png";
import catalog from "../images/service/catalog.png";
import confectionary from "../images/service/confectionary-1.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const CarasualComponent = () => {
  // let count = 0;
  // const [cardData, setCardData] = useState({});
  const [index, setIndex] = useState(0);

  let data = [
    {
      img: confectionary,
      h2: "Confectionary"
      // p: "printing of Toffie Cake sweets box"
    },
    {
      img: commercialPrinting,
      h2: "Commercial"
      // p: "printing of commercial purposes"
    },
    {
      img: fmcg,
      h2: "FMCG"
      // p: "printing of tissue boxes for college, university"
    },
    {
      img: lebelPrinting,
      h2: "Sticker/Label"
      // p: "printing of labels"
    },
    {
      img: education,
      h2: "Education"
      // p: "printing of education purposes"
    },
    {
      img: catalog,
      h2: "Catalog"
      // p: "printing of Catalogs for different companies"
    },
    {
      img: broucher,
      h2: "brochures"
      // p: "printing of Brouchers"
    }
  ];

  function gotoNext() {
    if (index === data.length - 1) {
      setIndex(0);
    } else {
      setIndex(prev => prev + 1);
    }
    // console.log(index);
  }

  function gotoPrev() {
    if (index === data.length - 1) {
      setIndex(0);
    } else {
      setIndex(prev => prev - 1);
    }
    // console.log(index);
  }

  function CardItem({ index }) {
    return (
      <div className="service-item card-1">
        <img src={data[index].img} alt="" />
        <h2>
          {data[index].h2}
        </h2>
        <p>
          {data[index].p}
        </p>
      </div>
    );
  }

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    });

    useEffect(
      () => {
        function tick() {
          savedCallback.current();
        }
        if (delay !== null) {
          let id = setInterval(tick, delay);
          return () => clearInterval(id);
        }
      },
      [delay]
    );
  }

  useInterval(() => {
    if (index === data.length - 1) {
      setIndex(0);
    } else {
      gotoNext();
    }
  }, 2000);

  return (
    <div className="row-1 service-carasual">
      <div className="circle-btn align-items-center justify-content-center">
        <div className="font-awesome" onClick={gotoPrev}>
          <FontAwesomeIcon className="icon" icon={faArrowLeft} />
        </div>
      </div>

      <div className="service-card ">
        {index > -1 && index < data.length
          ? <CardItem index={index} />
          : <CardItem index={0} />}
      </div>

      <div className="circle-btn align-items-center justify-content-center">
        <div className="font-awesome">
          <FontAwesomeIcon
            className="icon"
            icon={faArrowRight}
            onClick={gotoNext}
          />
        </div>
      </div>
    </div>
  );
};

export default CarasualComponent;
