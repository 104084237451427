import React from "react";
import { useState } from "react";
import cakeRush from '../../images/service/confectionary/cake-rush.jpeg'
import choclate from '../../images/service/confectionary/choclate.jpeg'
import choclate2 from '../../images/service/confectionary/chocolate.jpg'
import chocoCraies from '../../images/service/confectionary/choco-crakies.jpeg'
import cupCake from '../../images/service/confectionary/cupcake.jpeg'
import cupCakeBox from '../../images/service/confectionary/cupcake-box.jpeg'
import freshup from '../../images/service/confectionary/freshup.jpeg'
import jelly from '../../images/service/confectionary/jelly.jpeg'
import jelly2 from '../../images/service/confectionary/jelly2.jpeg'
import namkino from '../../images/service/confectionary/namkino.jpeg'
import ninmko from '../../images/service/confectionary/nimko.jpeg'
import candyAndChocolate from '../../images/service/confectionary/candy.jpeg'
import supari from '../../images/service/confectionary/supari.jpeg'
import wafer from '../../images/service/confectionary/wafers.jpeg'


import { LazyLoadImage } from "react-lazy-load-image-component";
const Confectionary = () => {
  return (
    <div className="row-1">
      <div className="service-cards align-items-center justify-content-center">
        <div className="row-1">
          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage
                alt={"supari boxes"}
                src={supari}
                effect="blur"
              />
            </div>
            <div className="service-page-info">
              <h3>Supari Boxes</h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"Candies"} src={candyAndChocolate} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Candies</h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"golden"} src={ninmko} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Nimco Boxes</h3>
            </div>
          </div>

            <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"golden"} src={namkino} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Nimco Boxes</h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage
                alt={"chocolate boxes"}
                src={choclate}
                effect="blur"
              />
            </div>
            <div className="service-page-info">
              <h3>Chocolate Boxes</h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage
                alt={"jelly boxes"}
                src={jelly2}
                effect="blur"
              />
            </div>
            <div className="service-page-info">
              <h3>Jelly Boxes</h3>
            </div>
          </div>

           <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage
                alt={"jelly boxes"}
                src={jelly}
                effect="blur"
              />
            </div>
            <div className="service-page-info">
              <h3>Jelly Boxes</h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"cup cake"} src={cupCake} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Cup Cake </h3>
            </div>
          </div>

            <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"cup cake"} src={cupCakeBox} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Cup Cake </h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage
                alt={"Biscuit boxes"}
                src={chocoCraies}
                effect="blur"
              />
            </div>
            <div className="service-page-info">
              <h3>Biscuit Boxes</h3>
            </div>
          </div>

          
          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage
                alt={"Biscuit boxes"}
                src={cakeRush}
                effect="blur"
              />
            </div>
            <div className="service-page-info">
              <h3>Biscuit Boxes</h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"wafer boxes"} src={wafer} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>Wafer boxes</h3>
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"cake Box"} src={freshup} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>bubble gum Boxes</h3>
            </div>
          </div>

           <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"chocolate"} src={choclate2} effect="blur" />
            </div>
            <div className="service-page-info">
              <h3>chocolate</h3>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Confectionary;
