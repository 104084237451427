import React from "react";
import img from "../../images/about/qualityPolicy.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const QualityPolicy = () => {
  return (
    <div className="about-quality-policy">
      <div className="container">
        <div className="row-1">
          <div className="quality-policy-text">
            <div className="inner-text">
              <h1>Quality Policy</h1>
              <div className="inner-img">
                <LazyLoadImage alt={"quality policy"} src={img} effect="blur"  />
              </div>

              <p>
                <span class="firstcharacter">E</span>
                Every member of Anjum Art Press is committed to put their best
                efforts in making the company successful in Printing & Packaging
                Industry and among their competitors in order to satisfy their
                customers and other imposed needs. We are achieving this by
                following:
              </p>
              <div className="quality-policy-list">
                <ul>
                  <li>Provide Product as per customer requirement.</li>
                  <li>Providing quality awareness to our team.</li>
                  <li>Continuous efforts to maintain consistent quality.</li>
                  <li>Adherence to customers delivery schedule.</li>
                  <li>Encouraging prevention over correction.</li>
                  <li>
                    Establishing and Reviewing quality objectives in order to
                    achieve continual improvements.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QualityPolicy;
