import React from "react";
//* Images
import certificate1 from "../../images/certificate/1.jpeg";

import { LazyLoadImage } from "react-lazy-load-image-component";

const Certification = () => {
  return (
    <div className="certification-info">
      <div className="container">
        <div className="row-1">
          <div className="certificate-image">
            <div className="certificate-inner-image">
              <div className="">
                <LazyLoadImage
                  alt={"certificate1"}
                  src={certificate1}
                  effect="blur"
                />
              </div>
            </div>
          </div>

          <div className="certificate-text">
            <div className="certificate-heading">
              <h1>
                Certificate of
                <span> Registration</span>
              </h1>
              <p>
                Anjum Art Press (Pvt.) Ltd. is a leading offset printer in
                Pakistan and we are happy to announce we have earned ISO
                certification after an extensive audit of the company’s internal
                operations. The audit has awarded us ISO Quality 9001
                certification. These are internationally recognized standards
                that ensure we are ready to meet the needs of our clients
                through an effective quality management system that not many
                organizations are prepared to submit themselves to the audit.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certification;
