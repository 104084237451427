import React from "react";

import booklet from "../../images/service/brouchersAndCatalog/booklet.jpg";
import broucher1 from "../../images/service/brouchersAndCatalog/broucher.jpg";
import broucher2 from "../../images/service/brouchersAndCatalog/roucher-2.jpg";
import broucher3 from "../../images/service/brouchersAndCatalog/broucher-3.jpeg";
import prospectus from "../../images/service/brouchersAndCatalog/prospectus.jpg";
import catalog from "../../images/service/brouchersAndCatalog/catalog.jpg";

import bulb1 from "../../images/service/brouchersAndCatalog/bulb-1.jpg";
import bulb3 from "../../images/service/brouchersAndCatalog/bulp-3.jpg";
import chickenMasala from "../../images/service/brouchersAndCatalog/chicken-masala.jpg";
import garamMasala from "../../images/service/brouchersAndCatalog/gram-masala.jpeg";
import saver1 from "../../images/service/brouchersAndCatalog/saver-1.jpg";
import saver2 from "../../images/service/brouchersAndCatalog/saver-2.png";
import saver3 from "../../images/service/brouchersAndCatalog/saver-3.jpeg";
import soap1 from "../../images/service/brouchersAndCatalog/soap.jpg";
import soap2 from "../../images/service/brouchersAndCatalog/soap-3.jpg";
import autoPart from "../../images/service/brouchersAndCatalog/autoPart.jpg";
import sparePart from "../../images/service/brouchersAndCatalog/spaer-part.jpg";
import burger from "../../images/service/brouchersAndCatalog/burger.jpg";
import pizzaBox from "../../images/service/brouchersAndCatalog/pizzaBox.jpg";

import { LazyLoadImage } from "react-lazy-load-image-component";

const Brouchers = () => {
  return (
    <div className="row-1">
      <div className="service-cards align-items-center justify-content-center">
        <div className="row-1">
          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"booklet"} src={booklet} effect="blur" />
            </div>
            <div className="service-page-info">
              {/* <h3>Booklet</h3> */}
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage
                alt={"prospectus"}
                src={prospectus}
                effect="blur"
              />
            </div>
            <div className="service-page-info">
              {/* <h3>prospectus</h3> */}
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"img2"} src={broucher1} effect="blur" />
            </div>
            <div className="service-page-info">
              {/* <h3>broacher</h3> */}
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"img2"} src={broucher2} effect="blur" />
            </div>
            <div className="service-page-info">
              {/* <h3>broacher</h3> */}
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"img2"} src={broucher3} effect="blur" />
            </div>
            <div className="service-page-info">
              {/* <h3>broacher</h3> */}
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"catalog1"} src={catalog} effect="blur" />
            </div>
            <div className="service-page-info">
              {/* <h3>Catalog</h3> */}
            </div>
          </div>

          {/* more */}
          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"bulb1"} src={bulb1} effect="blur" />
            </div>
            <div className="service-page-info">
              {/* <h3>Bulb</h3> */}
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"bulb-2"} src={bulb3} effect="blur" />
            </div>
            <div className="service-page-info">
              {/* <h3>bulb</h3> */}
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage
                alt={"chicken masala"}
                src={chickenMasala}
                effect="blur"
              />
            </div>
            <div className="service-page-info">
              {/* <h3>Chicken Masala</h3> */}
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage
                alt={"garam masala"}
                src={garamMasala}
                effect="blur"
              />
            </div>
            <div className="service-page-info">
              {/* <h3>Garam Masala</h3> */}
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"saver1"} src={saver1} effect="blur" />
            </div>
            <div className="service-page-info">
              {/* <h3>Saver</h3> */}
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"saver 2"} src={saver2} effect="blur" />
            </div>
            <div className="service-page-info">
              {/* <h3>saver</h3> */}
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"saver3"} src={saver3} effect="blur" />
            </div>
            <div className="service-page-info">
              {/* <h3>Saver</h3> */}
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"soap 1"} src={soap1} effect="blur" />
            </div>
            <div className="service-page-info">
              {/* <h3>Soap</h3> */}
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"soap 2"} src={soap2} effect="blur" />
            </div>
            <div className="service-page-info">
              {/* <h3>Soap</h3> */}
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"auto parts"} src={autoPart} effect="blur" />
            </div>
            <div className="service-page-info">
              {/* <h3>Auto Parts</h3> */}
            </div>
          </div>

          <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"spare Part"} src={sparePart} effect="blur" />
            </div>
            <div className="service-page-info">
              {/* <h3>Spare Part</h3> */}
            </div>
          </div>

            <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"burger"} src={burger} effect="blur" />
            </div>
            <div className="service-page-info">
              {/* <h3>Spare Part</h3> */}
            </div>
          </div>

            <div className="service-page-item">
            <div className="service-page-img">
              <LazyLoadImage alt={"pizza Box"} src={pizzaBox} effect="blur" />
            </div>
            <div className="service-page-info">
              {/* <h3>Spare Part</h3> */}
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Brouchers;
