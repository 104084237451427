import React from "react";
import { Link } from "react-router-dom";
import Title from "./title";
import { useState } from "react";
import axios from "axios";

import {
  FaFacebookF,
  FaLinkedinIn,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope
} from "react-icons/fa";

const Contacts = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [sent, setSent] = useState(false);

  async function onSubmitHandler(e) {
    e.preventDefault();
    let data = {
      name: name,
      phone: phone,
      message: message
    };

    const form = await axios
      .post("/api/form", { type: "query", data: data })
      .then(res => {
        setSent(true);
        resetForm();
      })
      .catch(() => {
        console.log("message not sent");
      });
  }

  // for reset inital data
  function resetForm() {
    setName("");
    setPhone("");
    setMessage("");

    setTimeout(() => {
      setSent(false);
    }, 3000);
  }

  const handlePhone = e => {
    console.log(e);

    if (phone.length <= 11) {
      setPhone(e.target.value);
    }
  };

  const handleDeletePhone = e => {
    if (e.keyCode === 8) {
      let tempPhone = phone.slice(0, -1);
      console.log(tempPhone);
      setPhone(tempPhone);
    }
  };

  return (
    <div className="contact-page">
      <Title title="Contacts" />
      <div className="container">
        <div className="row-1">
          <div className="contact-info">
            <h1>Contact Info</h1>
            <p>
              <FaMapMarkerAlt className="i" /> Plot No. A/76, S.I.T.E., GPO Box
              No. 400, Hyderabad-71900, Sindh, Pakistan.
            </p>

            <p>
              <FaPhoneAlt className="i" /> +92-307-2111333
              <ul>
                <li>+92-333-2111333</li>
                <li>+92-22-3413551-5</li>
              </ul>
            </p>

            <p>
              <FaEnvelope className="i" />
              info@anjumpress.com
            </p>

            <div className="social-links">
              <a
                className="link"
                href="https://www.facebook.com/211625908964400/posts/3998149503645336/?app=fbl"
              >
                <FaFacebookF />
              </a>

              <a
                className="link"
                href="https://www.linkedin.com/company/anjum-art-presspvtltd/"
              >
                <FaLinkedinIn />
              </a>
            </div>
          </div>

          <div className="contact-query">
            <form onSubmit={onSubmitHandler}>
              <div className="row-1">
                <div className="input-group msg">
                  <input
                    type="text"
                    placeholder="Name"
                    class="form-control"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    required
                  />
                </div>

                <div className="input-group phone">
                  <input
                    type="tel"
                    placeholder="number"
                    // pattern="[0-9]{4}-[0-9]{7}"
                    class="form-control"
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                 
                    maxLength={12}
              
                 
                    required
                  />
                </div>
              </div>

              <div className="input-group">
                <textarea
                  placeholder="Message"
                  className="form-control"
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                  required
                />
              </div>

              <button type="submit" className="btn-1 btn">
                send
              </button>

              {sent && <p className="message">Message sent</p>}
            </form>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
