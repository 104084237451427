import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Navbar from "./components//navbar.jsx";
import Home from "./components/Home.jsx";
import About from "./components/about/about.jsx";
import Footer from "./components//footer.jsx";
import Service from "./components/services/services.jsx";
import Contacts from "./components/contacts";
import Careers from "./components/careers/careers.jsx";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>

          <Route path="/home" exact>
            <Home />
          </Route>

          <Route path="/about" exact>
            <About />
          </Route>

           <Route path="/services" exact>
            <Service />
          </Route>

           <Route path="/careers" exact>
            <Careers />
          </Route>

          <Route path="/contacts" exact>
            <Contacts />
          </Route>
          
        </Switch>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
