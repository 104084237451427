import React from "react";
import SVG from "react-inlinesvg";
import { useState } from "react";
import printingImage from "../../assets/svg/printing.svg";
import Brouchers from "./brouchers";
import sweetsAndBakers from "./sweetAndBakers";
import Pharmacy from "./pharmasuetical";
import Confectionay from "./confectionary";
import Title from "../title";
import LabelAndStickers from "./labelAnsStickers";

const Services = () => {
  const [currentComponent, setCurrentComponent] = useState(Confectionay);
  const [activeTab, setActiveTab] = useState({
    sweetsAndBakers: "",
    brouchers: "",
    pharmacy: "",
    confectionery: "outer-shadow hover-in-shadow active",
    more: ""
  });

  const onHandleActive = e => {
    const localState = {
      sweetsAndBakers: "",
      brouchers: "",
      pharmacy: "",
      confectionery: "",
      labelAndStickers: ""
    };

    if (e === "sweetsAndBakers") {
      setCurrentComponent(sweetsAndBakers);
    }
    if (e === "brouchers") {
      setCurrentComponent(Brouchers);
    }
    if (e === "pharmacy") {
      setCurrentComponent(Pharmacy);
    }
    if (e === "confectionery") {
      setCurrentComponent(Confectionay);
    }

    if (e === "labelAndStickers") {
      setCurrentComponent(LabelAndStickers);
    }

    setActiveTab({ ...localState, [e]: "outer-shadow hover-in-shadow active" });
  };
  return (
    <div className="service-top">
      <Title title="Service" />
      <section className="service-page h-80">
        <div className="container">
          <div className="service-header row-1">
            <div className="service-text align-items-center justify-content-center">
              <div className="service-head">
                <h1 data-heading="">Our Services</h1>
              </div>
            </div>

            <div className="service-img ">
              <SVG src={printingImage} height="auto" title="Menu" />
            </div>
          </div>

          {/* <!-- about tab start --> */}
          <div class="row-1">
            <div class="about-tabs">
              <span
                onClick={() => onHandleActive("confectionery")}
                className={`tab-item ${activeTab.confectionery}`}
                data-target=".education"
              >
                Confectionary
              </span>

              <span
                onClick={() => onHandleActive("pharmacy")}
                className={`tab-item ${activeTab.pharmacy}`}
                data-target=".education"
              >
                Pharmaceutical
              </span>

              <span
                onClick={() => onHandleActive("sweetsAndBakers")}
                className={`tab-item ${activeTab.sweetsAndBakers}`}
                data-target=".sweetsAndBakers"
              >
                Sweets & Bakers
              </span>

              <span
                onClick={() => onHandleActive("labelAndStickers")}
                className={`tab-item ${activeTab.labelAndStickers}`}
                data-target=".education"
              >
                Stickers & Labels
              </span>

              <span
                onClick={() => onHandleActive("brouchers")}
                className={`tab-item ${activeTab.brouchers}`}
                data-target=".sweets"
              >
                Brochure & More
              </span>
            </div>
          </div>
          {/* <!-- about tab end --> */}

          {currentComponent}
        </div>
      </section>

      <section className="service-cards">
        <div className="container" />
      </section>
    </div>
  );
};

export default Services;
